.custom-file-input-container {
    position: relative;
}
.custom-file-input-container .label-container {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
}

.custom-file-input-container .label-container button {
    background-color: #C8C8C8;
    color: white;
    border-radius: 0px !important;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
    font-weight: bold;
    font-size: 16px;
    padding: 20px 24px;
}

.custom-file-input-container input{
    opacity: 0; 
    cursor: pointer;
    width: 100%;
}
.custom-file-input-container .label-name{
    display: inline-block;
    border: 1px solid #DFDFDF;
    background-color: white;
    padding: 17px 20px;
    width: calc(100% - 70px);;
    margin-right: 10px;
    vertical-align: top;
}

.custom-file-input-container.error {}
.custom-file-input-container.error .label-container {}
.custom-file-input-container.error .label-container .label-name {
    border-color: #c7001f;
}