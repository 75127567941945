.register-page {
    margin: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: cover;
    background-position: center center;
}

.right-panel {
    position: absolute;
    width: 50%;
    bottom: 0;
    top: 0;
    right: 0;
}

.blur {
    backdrop-filter: blur(8px);
    background: linear-gradient(206.81deg, rgb(255 255 255 / 34%) 1.48%, rgb(255 255 255 / 38%) 48.07%, rgba(255, 255, 255, 0) 100.48%);
}

.white-paper {
    background-color: white;
    opacity: 0.5;
}

.content-panel {
    text-align: center;
    padding-top: 200px;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2);
    padding-left: 150px;
    padding-right: 150px;
}

.content-panel h1, .register-panel h1 {
    font-size: 36px;
    font-family: 'TacticSans-Bld';
    font-weight: normal;
}

.content-panel p {
    font-size: 18px;
    text-align: left;
    margin-top: 20px;
}

.content-panel input, .register-panel input {
    width: 100%;
    margin-bottom: 20px;
    padding: 17px 20px;
    border-radius: 0px !important;
    outline: none;
    background-color: #F5F5F5;
    border: 1px solid #DFDFDF !important;
}

.register-page a {
    cursor: pointer;
    color: #C7001F;
}

.register-panel {
    padding-top: 50px;
    box-shadow: 0px 0px 8px 6px rgba(0, 0, 0, 0.2);
    padding-left: 100px;
    padding-right: 100px;
}

.register-panel h1 {
    text-align: center;
}

.register-panel input {
    width: 100%;
    margin-bottom: 20px;
    padding: 17px 20px !important;
    border-radius: 0px !important;
    outline: none;
    background-color: #F5F5F5;
    border: 1px solid #DFDFDF !important;
}

.register-panel .custom-dropdown-container input {
    height: 10px;
}

.register-panel .go-back-message {
    position: absolute;
    top: 30px;
    right: 30px;
}
.register-panel .go-back-message a {
    margin-left: 10px;
    border: 1px solid #C7001F;
    background-color: transparent;
    color: #C7001F;
    padding: 10px 35px;
    cursor: pointer;
}

.register-panel .submit {
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
    color: white;
    background-color: #C7001F;
    border: none;
    padding: 15px 10px;
    font-size: 16px;
    cursor: pointer;
    font-weight: bold;
}

.register-panel .submit:disabled {
    background-color: rgba(199, 0, 31, 0.51);
    padding: 0px;
}

.register-panel .submit:disabled .lds-ellipsis {
    margin-top: -15px;
    height: 60px;
}

.register-page input.error {
    border-color: #c7001f !important;
}

.file-inputs-container {}
.file-inputs-container .file-item-container {
    display: inline-block;
    width: 50%;
}

.file-inputs-container .file-item-container:first-child {
    padding-right: 15px;
}
.file-inputs-container .file-item-container:last-child {
    padding-left: 15px;
}

@media (min-width: 320px) and (max-width: 480px) {
    .right-panel {
        width: 100%;
        left: 0;
        overflow: hidden;
    }

    .content-panel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .register-panel {
        padding-left: 10px;
        padding-right: 11px;
    }

    .file-inputs-container {}
    .file-inputs-container .file-item-container {
        display: block;
        width: 100%;
    }

    .file-inputs-container .file-item-container:first-child {
        padding-right: 0px;
    }
    .file-inputs-container .file-item-container:last-child {
        padding-left: 0px;
    }
}

@media (min-width: 480px) and (max-width: 900px) and (orientation: landscape) {
    .right-panel {
        width: 100% !important;
        left: 0;
        overflow: hidden;
    }

    .content-panel {
        padding-left: 10px;
        padding-right: 10px;
    }

    .register-panel {
        padding-left: 10px;
        padding-right: 11px;
    }

    .file-inputs-container {}
    .file-inputs-container .file-item-container {
        display: block;
        width: 100%;
    }

    .file-inputs-container .file-item-container:first-child {
        padding-right: 0px;
    }
    .file-inputs-container .file-item-container:last-child {
        padding-left: 0px;
    }
}

@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .right-panel {
        width: 65%;
    }

    .content-panel {
        padding-left: 50px;
        padding-right: 50px;
    }

    .file-inputs-container .file-item-container {
        display: block;
        width: 100%;
    }

    .file-inputs-container .file-item-container:first-child {
        padding-right: 0px;
    }
    .file-inputs-container .file-item-container:last-child {
        padding-left: 0px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {
    .right-panel {
        width: 65%;
    }

    .content-panel {
        padding-left: 50px;
        padding-right: 50px;
    }

    .file-inputs-container .file-item-container {
        display: block;
        width: 100%;
    }

    .file-inputs-container .file-item-container:first-child {
        padding-right: 0px;
    }
    .file-inputs-container .file-item-container:last-child {
        padding-left: 0px;
    }
}

@media (min-width: 1200px) and (orientation: landscape) {

}