.custom-input {
    width: 100%;
    background-color: #F5F5F5;
    resize: none;
    outline: none;
    border: 1px solid #E7E7E7;
    padding: 13px 15px;
    margin-bottom: 15px;
}

.custom-input:last-child {
    margin-bottom: 0px;
}

.custom-input::placeholder {
    color: #868686 !important;
}

.custom-input:placeholder-shown {
    color: #868686 !important;
}

.custom-input.with-value {
    background-color: white;
}

.password-input {
    position: relative;
} 
.password-input img {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
} 