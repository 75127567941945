.cars-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.cars-page .header {
    margin-right: 30px;
}
.cars-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.cars-page .header .column:first-child {
    position: relative;
    padding-top: 5px;
}

.cars-page .header .column:first-child span {
    cursor: pointer;
}

.cars-page .header .column:first-child span img {
    margin-right: 10px;
}

.cars-page .header .column:last-child button {
    border-radius: 0px;
    background-color: #C8C8C8;
    color: white;
    padding: 15px 31px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.cars-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.cars-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0px 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}


.cars-page .car-items {
    margin-top: 20px;
}

.cars-page .car-item {
    width: 530px;
    display: inline-block;
    background-color: white;
    margin-bottom: 20px;
    margin-right: 20px;
    vertical-align: top;
}

.cars-page .car-item .image-container {
    overflow: hidden;
    height: 184px;
    cursor: pointer;
    width: 100%;
}

.cars-page .car-item img.car-img {
    width: 71%;
    margin-top: -14px;
    margin-left: 51px;
}

.cars-page .car-item img.car-img.empty {
    width: 53%;
    margin-top: 14px;
    margin-left: 133px;
}

.cars-page .car-selected-container .car-selected-info .no-have-image-container {
    background-color: white;
    margin-bottom: 15px;
    text-align: center;
    padding-top: 40px;
}

.cars-page .car-selected-container .car-selected-info .no-have-image-container img.car-img {
    width: 60%;
}


.cars-page .car-item .details-container {
    padding: 20px;
    padding-bottom: 30px;
    cursor: pointer;
    border: 2px solid transparent;
    position: relative;
    transition: border-color 200ms;
}

.cars-page .car-item .details-container.have-need-ssi-message {
    padding-bottom: 67px;
}

.cars-page .car-item .details-container:hover {
    border: 2px solid #C7001F;
}

.cars-page .car-item .details-container .car-name {
    font-size: 28px;
    margin-bottom: 30px;
    font-family: "TacticSans-MedIt", serif;
}

.cars-page .car-item .details-container .car-name.mark {
    color: #C7001F;
}

.cars-page .car-item .details-container .car-properties .column:first-child {
    color: #B2B2B2;
}

.cars-page .car-item .details-container .car-properties .column:last-child {
    padding-left: 0px;
}

.cars-page .car-item .details-container .car-properties .row {
    padding-bottom: 0px;
    padding-top: 10px;
}

.cars-page .car-item .details-container .car-properties .car-number-container {
    position: relative;
}

.cars-page .car-properties .car-number-container img {
    position: absolute;
    right: 5px;
    top: -5px;
    cursor: pointer;
}

.cars-page .car-properties .delete-btn-container img {
    position: absolute;
    right: 7px;
    top: -5px;
    cursor: pointer;
}

.cars-page .car-properties .delete-car-row {
    align-items: center;
}

.cars-page .car-properties .delete-car-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cars-page .car-properties .delete-car-label {
    color: #B2B2B2;
}

.cars-page .car-item .details-container .view-car-link {
    color: #C7001F;
    position: absolute;
    bottom: 20px;
    right: 20px;
}

.cars-page .car-item .details-container .view-car-link img {
    vertical-align: middle;
}


.cars-page .car-item .details-container .need-ssi-message {
    color: #E1121A;
    position: absolute;
    bottom: 20px;
    left: 17px;
}

.cars-page .car-item .details-container .need-ssi-message img {
    vertical-align: middle;
}


.car-selected-info {
    display: inline-block;
    width: 530px;
    margin-right: 20px;
}

.to-list-container {
    display: inline-block;
    vertical-align: top;
}

.to-list-container .create-to-request-btn {
    border: none;
    border-radius: 0 !important;
    background-color: #c7001f;
    color: white;
    width: 100%;
    margin-top: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.to-list {
    border-collapse: separate;
    border-spacing: 0 10px;
    width: 530px;
    background-color: white;
    padding-top: 20px;
}

.to-list td {
    text-align: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.to-list tr {
    margin-bottom: 10px;
}

.to-list tr.complete {
    background: linear-gradient(90deg, #BDE7D0, #EFF9F4);
}

.to-list tr.missing {
    background: linear-gradient(90deg, #F6B8BA, #FEF7F7);
}

.to-list tr.await {
    background: linear-gradient(90deg, #CDCDCD, #F9F9F9);
}

.download-book-btn {
    border: 1px solid #c7001f;
    border-radius: 0 !important;
    background-color: white;
    color: #c7001f;
    width: 100%;
    margin-top: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
}

.download-book-btn img {
    vertical-align: middle;
}

.car-selected-container {
    margin-top: 30px;
}

.car-selected-container .need-go-to-service-container {
    padding: 20px;
    background-color: white;
}
.car-selected-container .need-go-to-service-container .title {
    color: #E1121A;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 18px;
}
.car-selected-container .need-go-to-service-container .article {
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 10px;
}
.car-selected-container .need-go-to-service-container .description {

}

.car-selected-container .car-selected-info {}
.car-selected-container .car-selected-info img.car-img {
    margin-bottom: 20px;
    width: 100%;
}
.car-selected-container .car-selected-info .details-container {
    background-color: white;
    padding: 20px;
    margin-bottom: 20px;
    padding-bottom: 30px;
}
.car-selected-container .car-selected-info .details-container .car-name {
    font-size: 28px;
    font-weight: bold;
    font-family: "TacticSans-MedIt";
    color: #C7001F;
    margin-bottom: 20px;
}
.car-selected-container .car-selected-info .details-container .car-properties .row {
    padding-bottom: 0px;
}
.car-selected-container .car-selected-info .details-container .car-properties .column:first-child {
    color: #B2B2B2;
}
.car-selected-container .car-selected-info .details-container .car-properties .column:last-child {
    padding-left: 0px !important;
}


.change-car-number-modal, .delete-car-modal {
    max-width: 415px !important;
    border-radius: 0px !important;
}

.change-car-number-modal .title, .delete-car-modal .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin: 25px;
    margin-top: 5px;
}

.delete-car-modal .title {
    color: #c7001f;
}

.delete-car-modal p {
    text-align: center;
    width: 80%;
    font-size: 16px;
    line-height: 25px;
    margin-left: auto;
    margin-right: auto;
}

.delete-car-modal .error {
    color: rgba(229, 0, 9, 1);
    padding-left: 15px;
    font-size: 14px;
    margin: 10px 0 20px;
}


.change-car-number-modal .grid .row .column, .delete-car-modal .grid .row .column {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.change-car-number-modal .grid, .delete-car-modal .grid {
    margin-top: 10px;
}

.change-car-number-modal .grid .row .column:first-child {
    color: #B2B2B2;
}

.change-car-number-modal .grid .row .column:last-child {
    color: #23B062;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .cars-page {
        padding-left: 0px;
        padding-right: 0px;
    }

    .cars-page .header h1 {
        font-size: 21px;
    }

    .cars-page .header {
        margin-left: 45px;
    }

    .cars-page .header .column:first-child img{
        vertical-align: text-bottom;
    }

    .car-selected-info {
        width: 100%;
    }

    .to-list-container {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 25px;
    }

    .to-list {
        width: 100%;
    }

    .cars-page .car-item {
        width: 100%;
    }

    .cars-page .car-item .car-name.mark .mark-element{
        vertical-align: top;
    }

    .cars-page .car-item .details-container .car-name {
        line-height: 25px;
    }

    .change-car-number-modal, .delete-car-modal {
        width: 95% !important;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .cars-page {
        padding-left: 140px;
    }

    .cars-page .car-item {
        width: auto;
    }

    .car-selected-container .car-selected-info {
        width: auto;
        margin-bottom: 20px;
    }

    .car-selected-container .car-selected-info img.car-img {
        width: 100%;
    }

    .to-list-container {
        width: calc(100% - 20px);
        margin-bottom: 25px;
    }

    .to-list-container .to-list {
        width: 100%;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}
