.my-dealer-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.my-dealer-page .header {
    margin-right: 30px;
}
.my-dealer-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.my-dealer-page .header .column:first-child {
    position: relative;
    padding-top: 5px;
}

.my-dealer-page .header .column:last-child{
    text-align: right;
}

.my-dealer-page .header .column:last-child button {
    border-radius: 0px;
    border: 1px solid #C7001F;
    background-color: white;
    color: #C7001F;
    font-weight: bold;
    font-size: 16px;
    padding: 15px 31px;
    cursor: pointer;
}
.my-dealer-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.my-dealer-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0px 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}

.my-dealer-page .current-dealer-info-container {
    margin-top: 25px;
}

.my-dealer-page .current-dealer-info-container .current-dealer-place-info-block {
    background-color: white;
    padding: 25px;
    width: 530px;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    vertical-align: top;
}
.my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
}
.my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .address {
    margin-bottom: 15px;
}
.my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .address img {
    vertical-align: bottom;
    margin-right: 10px;
}
.my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .map-container {}
.my-dealer-page .current-dealer-info-container .contacts-container {
    background-color: white;
    padding: 25px;
    width: 530px;
    height: 444px;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
    vertical-align: top;
}
.my-dealer-page .current-dealer-info-container .contacts-container .mail, .my-dealer-page .current-dealer-info-container .contacts-container .site, .my-dealer-page .current-dealer-info-container .contacts-container .phone, .my-dealer-page .current-dealer-info-container .contacts-container .note {

}

.my-dealer-page .current-dealer-info-container .contacts-container>div.without-notes {
    margin-bottom: 25px;
    margin-top: 0px;
}

.my-dealer-page .current-dealer-info-container .contacts-container .mail {}
.my-dealer-page .current-dealer-info-container .contacts-container .site a {
    color: #0156FF;
    cursor: pointer;
}

.my-dealer-page .current-dealer-info-container .contacts-container .note {

}
.my-dealer-page .current-dealer-info-container .contacts-container img {
    vertical-align: middle;
    margin-right: 5px;
}
.my-dealer-page .block-title {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.my-dealer-page .empty-marketing {
    font-weight: bold;
    font-size: 18px;
    margin-top: 15px;
    margin-bottom: 15px;
    color: #B2B2B2;
}
.my-dealer-page .addition-info-block {
    background-color: white;
    padding: 25px;
    margin-right: 25px;
}
.my-dealer-page .marketing-items {}
.my-dealer-page .marketing-items .marketing-item {
    display: inline-block;
    width: 530px;
    background-color: white;
    padding: 25px;
    margin-right: 15px;
    margin-bottom: 15px;
    position: relative;
    padding-bottom: 45px;
    vertical-align: top;
}
.my-dealer-page .marketing-items .marketing-item .item-title {
    font-size: 18px;
    font-weight: bold;
    color: #c7001f;
    margin-top: 15px;
    margin-bottom: 10px;
}
.my-dealer-page .marketing-items .marketing-item .item-image {width: 100%}
.my-dealer-page .marketing-items .marketing-item .item-image img {width: 100%}
.my-dealer-page .marketing-items .marketing-item .item-text {}
.my-dealer-page .marketing-items .marketing-item .item-date {
    position: absolute;
    right: 15px;
    bottom: 15px;
}
.my-dealer-page .marketing-items .marketing-item .item-date img {
    vertical-align: bottom;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .my-dealer-page {
        padding-left: 12px;
        padding-right: 0px;
    }

    .my-dealer-page .header h1 {
        font-size: 21px;
    }

    .my-dealer-page .header {
        margin-left: 45px;
        margin-right: 0px;
    }

    .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block,
    .my-dealer-page .current-dealer-info-container .contacts-container,
    .my-dealer-page .marketing-items .marketing-item{
        width: calc(100% - 15px)
    }

    .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block {
        overflow: hidden;
    }

    .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .map-container {
        margin-left: -95px;
    }

    .my-dealer-page .addition-info-block {
        margin-right: 15px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .my-dealer-page {
        padding-left: 140px;
    }

    .my-dealer-page .current-dealer-info-container .contacts-container {
        width: 245px;
    }

    .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block {
        width: calc(100% - 278px);
        overflow: hidden;
    }

    .my-dealer-page .current-dealer-info-container .current-dealer-place-info-block .map-container {
        margin-left: -75px;
    }

    .my-dealer-page .marketing-items .marketing-item {
        width: auto;
        margin-right: 25px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}