.custom-textarea {
    width: 100%;
    background-color: #F5F5F5;
    resize: none;
    outline: none;
    border: 1px solid #E7E7E7;
    height: 95px;
    padding: 13px 15px;
}

.custom-textarea::placeholder {
    color: #868686 !important;
}

.custom-textarea:placeholder-shown {
    color: #868686 !important;
}

.custom-textarea.with-value {
    background-color: white;
}