.menu-container {
    position: fixed;
    top: 0;
    width: 290px;
    background-color: white;
    z-index: 1000;
}

.menu-container .container {
    position: relative;
}

.menu-container .container .logo {
    position: absolute;
    top: 6px;
    left: 0px;
}

.menu-container .link-to-main-site {
    position: absolute;
    bottom: 10px;
    font-size: 16px;
    text-decoration: underline;
}
.menu-container .link-to-main-site img {
    vertical-align: middle;
    margin-right: 10px;
}

.menu-items {
    padding: 40px;
    padding-top: 10px;
}

.menu-items .menu-item {
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    padding-left: 45px;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 16px;
}

.menu-items .menu-item img {
    position: absolute;
    left: 10px;
    top: 11px;
}

.menu-items .menu-item.active {
    background-color: #C7001F;
    color: white;
    font-weight: bold;
}

.menu-items .menu-item.exit-item {
    position: absolute;
    bottom: 40px;
}

.menu-container .header {
    width: 220px;
    margin-left: 40px;
    padding-top: 24px;
}

.logo-container {
    padding-top: 3px!important;
    text-align: center;
}

.column.logo-container img {}

.menu-container .name-container {
    position: absolute;
    bottom: 106px;
    left: 50px;
    color: #868686;
}

.menu-container .first-name {
    font-size: 26px;
    height: 20px;
}

.menu-container .last-name {
    font-size: 12px;
    font-weight: bold;
}

.menu-container .header .column {
    padding-left: 0px !important;
    padding-right: 0px !important;
}


.menu-container .header-logo-only {
    display: none;
}

.menu-container .header-logo-only {
    padding: 10px 37px 0px 37px;
}

.mobile-menu-btn {
    display: none;
}


.menu-container .unread-marker {
    position: absolute;
    top: 7px;
    left: 23px;
    border-radius: 100px;
    width: 15px;
    height: 15px;
    background-color: #c7001f;
    border: 2px solid white;
}

.menu-container .column.name-container {
    padding-left: 10px!important;
    padding-top: 4px!important;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) {

    .menu-container {
        display: none;
    }
    .menu-container.show {
        display: block;
    }
    .mobile-menu-btn {
        display: block;
        position: absolute;
        top: 17px;
        left: 22px;
    }

    .news-page .header .column:first-child label {
        top: -5px !important;
        left: 114px !important;
    }

    .menu-items .menu-item.exit-item {
        position: absolute;
        bottom: 0px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .menu-container {
        width: 121px;
    }
    .menu-container .header-logo-only {
        display: block;
    }

    .menu-container .header{
        display: none !important;
    }

    .menu-container .label-text {
        display: none;
    }

    .menu-container .menu-items .menu-item {
        height: 45px;
        width: 45px;
        margin-bottom: 15px;
    }
    .header {
        margin-right: 0px !important;
    }

    .menu-container .link-to-main-site {
        left: 50px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}
