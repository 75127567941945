@keyframes fade {
    0% {
        opacity: 0.5;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.5;
    }
}

.messages-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.messages-page .header {
    margin-right: 30px;
}
.messages-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.messages-page .header .column:first-child {
    position: relative;
    padding: 5px 0 0 14px;
}

.messages-page .header .column:last-child {
    text-align: right;
}

.messages-page .header .column:last-child button {
    border-radius: 0px;
    background-color: #c7001f;
    color: white;
    padding: 14px 31px;
    border: none;
    cursor: pointer;
}
.messages-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.messages-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0px 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}


.messages-page .year-selector {
    display: inline-block;
    vertical-align: top;
    margin-right: 25px;
}

.messages-page .chats-container {
    background-color: white;
}
.messages-page .chats-container .chat-item {
    height: 120px;

    position: relative;
    cursor: pointer;
}

.messages-page .chats-container .chat-item:hover {
    background-color: rgba(223, 223, 223, 0.8);
}

.messages-page .chats-container .chat-item-inner {
    margin-left: 20px;
    height: 120px;
    margin-right: 20px;
    border-bottom: 1px solid #DFDFDF;
}

.messages-page .chats-container .chat-item:last-child .chat-item-inner {
    border-bottom: none;
}

.messages-page .chats-container .chat-item.active {
    background-color: #DFDFDF;
}
.messages-page .chats-container .chat-item .type {
    font-weight: bold;
    font-size: 16px;
    position: absolute;
    top: 20px;
    left: 20px;
}
.messages-page .chats-container .chat-item .department {
    position: absolute;
    bottom: 50px;
    left: 20px;
}
.messages-page .chats-container .chat-item .sub-type {
    position: absolute;
    bottom: 17px;
    left: 20px;
    color: #0050C7;
}
.messages-page .chats-container .chat-item .messages-count {
    position: absolute;
    bottom: 50px;
    right: 20px;
}
.messages-page .chats-container .chat-item .messages-count.need-close {
    right: 35px;
}
.messages-page .chats-container .chat-item .need-close-icon {
    position: absolute;
    bottom: 54px;
    right: 20px;
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background: #FF7168;
    animation: fade 1.5s infinite;
    animation-timing-function: ease-in-out;
}
.messages-page .chats-container .chat-item .rating {
    position: absolute;
    top: 23px;
    right: 92px;
    transform: scaleX(-1);
}
.messages-page .chats-container .chat-item .date {
    position: absolute;
    top: 20px;
    right: 20px;
    color: #B2B2B2;
}
.messages-page .chats-container .chat-item .state {
    position: absolute;
    bottom: 17px;
    right: 20px;
    color: #B2B2B2;
    font-weight: bold;
}

.messages-page .chats-container .chat-item .date .mark-element {
    margin-left: 5px;
    margin-right: 0px;
    vertical-align: text-top;
}

.chat-container {
    border-left: 1px solid #DFDFDF;
    padding: 25px;
}
.chat-container .chat-messages-container {
    overflow-y: scroll;
    padding-right: 20px;
}

.chat-container .dealer-chat-info {
    height: 50px;
    margin-bottom: 25px;
    background-color: white;
    padding-top: 16px;
    padding-left: 29px;
}

.chat-container .chat-messages-container .message {
    position: relative;
    padding: 32px 37px;
    margin-bottom: 25px;
    width: calc(100% - 40px);
}
.chat-container .chat-messages-container .message.in {
    background-color: white;
    margin-left: 40px;
}
.chat-container .chat-messages-container .message.out {
    background-color: #DFDFDF;
    margin-right: 40px;
}

.chat-container .chat-messages-container .message.out .message-marker {
    width: 71px;
    height: 35px;
    position: absolute;
    bottom: -1px;
    right: -36px;
}

.chat-container .chat-messages-container .message.in .message-marker {
    width: 71px;
    height: 35px;
    position: absolute;
    bottom: -1px;
    left: -36px;
}

.chat-container .chat-messages-container .message .date {
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.chat-container .create-message-block {
    position: relative;
    margin-top: 15px;
}
.chat-container .create-message-block .input-for-message {
    padding: 15px 20px;
    width: 100%;
    outline: none;
    border: 1px solid #DFDFDF;
    border-radius: 0px;
}
.chat-container .create-message-block .send-btn {
    position: absolute;
    right: 10px;
    top: 12px;
    cursor: pointer;
}

.chat-container .close-chat-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 85%;
    margin-top: 30px;
}

.chat-container .close-chat-block .close-chat-buttons {
    display: flex;
    gap: 3px;
}

.chat-container .close-chat-block .close-chat-buttons .button {
    border: none;
    border-radius: 2px;
    height: 26px;
    color: #F5F5F5;
    font-size: 14px;
    padding: 4px 12px;
    cursor: pointer;
}

.chat-container .close-chat-block .close-chat-buttons .button-yes {
    background: #23B062;
}

.chat-container .close-chat-block .close-chat-buttons .button-no {
    background: #c7001f;
}

.select-chat-message {
    text-align: center;
    padding-top: 30px;
    font-weight: bold;
    font-size: 21px;
    color: #B2B2B2;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .messages-page {
        padding-left: 0px;
        padding-right: 0px;
    }

    .messages-page .header h1 {
        font-size: 21px;
    }

    .messages-page .header {
        margin-left: 45px;
        margin-right: 0px;
    }

    .new-chat-modal {
        width: 95% !important;
    }

    .years-selector-container-block {
        margin-bottom: 25px;
        margin-top: 25px;
        padding-left: 15px;
    }

    .years-selector-container-block .year-selector .years-slice-container {
        top: 16px;
    }

    .create-message-text {
        display: none;
    }

    .messages-page .header .column:last-child button {
        padding: 15px;
    }
    .messages-page .header .column:last-child button img {
        margin-right: 0px;
    }

    .messages-page .for-mobile .chat-header h1 {
        font-family: "TacticSans-MedIt";
        font-size: 21px;
    }

    .messages-page .for-mobile .chat-container {
        border-left: none;
    }

    .messages-page .for-mobile .chat-header {
        margin-left: 62px;
        padding-top: 15px;
    }
    .messages-page .for-mobile .chat-header img {
        vertical-align: sub;
    }

    .messages-page .chats-container .chat-item .type {
        width: 50%;
    }

    .messages-page .chats-container .chat-item, .messages-page .chats-container .chat-item-inner {
        height: 120px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .messages-page {
        padding-left: 140px;
    }

    .messages-page .chats-container {
        margin-right: 13px;
        margin-top: 15px;
    }

    .messages-page .year-selector{
        margin-top: 15px;
    }
    .messages-page .year-selector .years-slice-container {
        top: 18px;
    }

    .messages-page .for-mobile .chat-header {
        padding: 35px 10px;
    }

    .messages-page .for-mobile .chat-header img {
        vertical-align: text-top;
    }

    .messages-page .for-mobile .chat-header h1 {
        font-family: "TacticSans-MedIt";
        font-size: 26px;
    }

    .messages-page .for-mobile .chat-container {
        border-left: none;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
