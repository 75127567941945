.road-help-page {
    padding-left: 320px;
    padding-right: 15px;
    background-color: #F5F5F5;
}

.road-help-page .header {
    margin-right: 30px;
}
.road-help-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.road-help-page .header .column:first-child {
    position: relative;
    padding-top: 5px;
}

.road-help-page .header .column:last-child button {
    border-radius: 0px;
    background-color: #C8C8C8;
    color: white;
    padding: 15px 31px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.road-help-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.road-help-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0px 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}

.road-help-page .card-container {
    
}


.road-help-page .card-container .card-image {
    position: relative;
}
.road-help-page .card-container .card-image .card-number {
    font-family: For-Cards;
    position: absolute;
    top: 173px;
    left: 34px;
    font-size: 25px;
    color: white;
}

.road-help-page .card-container .bar-code {
    position: absolute;
    top: 180px;
    left: 105px;
}

.road-help-page-container  .card-image img {
    width: 473px;
}

.road-help-page-container .for-mobile .card-image img {
    width: 350px;
}
.road-help-page-container .for-mobile .card-image .card-number {
    top: 116px;
    left: 12px;
    font-size: 20px;
}

.road-help-page  .card-container .for-mobile  .bar-code {
    top: 133px !important;
    left: 78px !important;
}

.road-help-page  .card-container .for-mobile {
    padding-top: 20px;
}

.road-help-page  .card-container .for-mobile>div:first-child, .road-help-page  .card-container .for-mobile>div:nth-child(2) {
    text-align: center;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) {
    .road-help-page {
        padding-left: 10px;
        padding-right: 10px;
    }

    .road-help-page .header {
        margin-right: 0px;
        margin-left: 40px;
    }

    .road-help-page .header h1 {
        font-size: 21px;
    }

    .road-help-page-container .custom-left-panel, .road-help-page-container .custom-right-panel {
        width: auto !important;
        margin-right: 20px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {

}


/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .road-help-page {
        padding-left: 140px;
    }

    .road-help-page-container .custom-left-panel {
        width: calc(100% - 25px);
    }
    .road-help-page-container .custom-right-panel {
        width: auto;
        margin-right: 25px;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {

}

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {

}