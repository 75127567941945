.faq-accordion-container {
}
.faq-accordion-container .faq-accordion-item-container {
    position: relative;
    background: #FFFFFF;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 15px;
    cursor: pointer;
}
.faq-accordion-container .faq-accordion-item-container.is-open {}
.faq-accordion-container .faq-accordion-item-container .faq-accordion-item-title {
    font-weight: 700;
    font-size: 18px;
}
.faq-accordion-container .faq-accordion-item-container img.faq-accordion-indicator {
    position: absolute;
    top: 20px;
    right: 20px;
}
.faq-accordion-container .faq-accordion-item-container .faq-accordion-item-content {
    margin-top: 25px;
}