.add_new_contact__container {
  background-color: white;
  padding: 25px;
}

.add_new_contact__buttons_container {
  display: flex;
  justify-content: space-between;
}

.add_new_contact__btn {
  border: none;
  border-radius: 0;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  padding: 15px;
  width: 100%;
}

.required {
  color: red
}

.add_new_contact__btn.add_new_contact__reject {
  padding: 13px;
  box-sizing: border-box;
  border: 2px solid red;
  color: red;
  background-color: white;
}

.add_new_contact__phone-input {
  width: 100%;
  resize: none;
  outline: none;
  border: 1px solid #E7E7E7;
  padding: 13px 15px;
  margin-bottom: 15px;
}
.add_new_contact__phone-input.error {
  border: 1px solid #c7001f;
}
.add_new_contact__phone-input:last-child {
  margin-bottom: 0;
}
.add_new_contact__phone-input::placeholder {
  color: #868686 !important;
}
.add_new_contact__phone-input:placeholder-shown {
  color: #868686 !important;
}
.add_new_contact__phone-input.with-value {
  background-color: white;
}

@media screen and (max-width: 768px) {
  .add_new_contact__grid_container > .row > .column:nth-child(2) {
    margin-top: 28px;
  }
}
