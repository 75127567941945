.settings-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.settings-page .header {
    margin-right: 30px;
}
.settings-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}

.settings-page .header .column:first-child {
    position: relative;
    padding-top: 5px;
}

.settings-page .header .column:last-child button {
    border-radius: 0;
    background-color: #C8C8C8;
    color: white;
    padding: 15px 31px;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.1);
}
.settings-page .header .column:last-child button img {
    vertical-align: middle;
    margin-right: 10px;
}

.settings-page .header .column:first-child label {
    position: absolute;
    top: 5px;
    left: 186px;
    background-color: #E1121A;
    padding: 0 8px;
    color: white;
    border-radius: 100px !important;
    font-size: 16px;
    font-weight: bold;
}

.settings-page-container .custom-left-panel {
    margin-right: 20px;
}
.settings-page-container .custom-left-panel, .settings-page-container .custom-right-panel {
    display: inline-block;
    width: 530px;
    vertical-align: top;
}

.titled-block .title {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 15px;
    margin-top: 25px;
}
.titled-block .content {
    background-color: white;
    padding: 25px;
}

.communication-settings-block {
    padding-top: 35px !important;
}

.communication-settings-block .row {
    padding: 5px !important;
}

.settings-page .custom-title {
    margin-bottom: 10px;
}

.settings-page .save-btn {
    width: 100%;
    background-color: #c7001f;
    color: white;
    border: none;
    border-radius: 0;
    padding: 15px;
    cursor: pointer;
    margin-top: 15px;
    font-weight: bold;
}

.settings-page .save-btn:disabled {
    background-color: #C8C8C8 !important;
    cursor: default;
}

.settings-page .user-id {
    color: #777777;
    padding-left: 5px;
}

.remove-account-btn {
    width: 100%;
    background-color: #C8C8C8;
    color: white;
    border: none;
    border-radius: 0;
    padding: 15px;
    cursor: pointer;
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    transition: background-color 200ms;
}
.remove-account-btn img {
    vertical-align: middle;
    margin-right: 10px;
}
.remove-account-btn:hover {
    background-color: #c7001f;
}

.remove-account-modal {
    width: 550px !important;
    border-radius: 0 !important;
}
.remove-account-modal .title {
    font-weight: bold;
    font-size: 18px;
    text-align: center;
    margin: 5px 25px 25px;
}

.remove-account-modal p {
    text-align: center;
}

.remove-account-modal .grid .row .column {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.remove-account-modal .grid {
    margin-top: 30px;
}

.remove-account-modal .grid .row .column:first-child {
    color: #B2B2B2;
}

.remove-account-modal .grid .row .column:last-child {
    color: #E1121A;
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) {
    .settings-page {
        padding-left: 10px;
    }

    .settings-page .header {
        margin-right: 0;
        margin-left: 40px;
    }

    .settings-page .header h1 {
        font-size: 21px;
    }

    .settings-page-container .custom-left-panel, .settings-page-container .custom-right-panel {
        width: auto !important;
        margin-right: 20px;
    }
}

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .settings-page {
        padding-left: 140px;
    }

    .settings-page-container .custom-left-panel {
        width: calc(100% - 25px);
    }
    .settings-page-container .custom-right-panel {
        width: auto;
        margin-right: 25px;
    }
}
