.year-selector {
    position: relative;
    padding-left: 50px;
    width: 341px;
    height: 53px;
    overflow: hidden;
    background-color: white;
    user-select: none;
}
.year-selector .calendar-img {
    position: absolute;
    top:15px;
    left: 15px;
}

.year-selector .move-to-left, .year-selector .move-to-right {
    position: absolute;
    top: 0px;
    height: 53px;
    padding-top: 15px;
    cursor: pointer;
} 

.year-selector .move-to-left {
    left: 40px;
    background: linear-gradient(270deg, #ffffff00 0 26%, white 40% 100%);
    padding-right: 10px;
    padding-left: 15px;
}
.year-selector .move-to-right {
    right: 0px;
    padding-right: 10px;
    padding-left: 15px;
    background: linear-gradient(90deg, #ffffff00 0 25%, white 50% 100%);
}
.year-selector .years-slice-container {
    position: absolute;
    width: 1000px;
    top: 14px;
    left: 66px;
    overflow: hidden;
}
.year-selector .years-container {
    transition: margin-left 200ms;
    text-align: left;
}
.year-selector .years-container .year-item {
    display: inline-block;
    margin-right: 10px;
    cursor: pointer;
    font-size: 14px;
}
.year-selector .years-container .year-item.selected {
    font-weight: bold;
    color: #C7001F;
}


/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {  }

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}
