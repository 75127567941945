.service-request-page {
    padding-left: 320px;
    background-color: #F5F5F5;
}

.service-request-page .header {
    margin-right: 30px;
}
.service-request-page .header h1 {
    font-family: "TacticSans-MedIt";
    font-size: 36px;
}


.service-request-form {
    width: 530px;
    background-color: white;
    padding: 20px;
    margin-top: 50px;
}


.service-request-form button {
    width: 100%;
}



.service-request-form .send-service-request {
    background-color: #c7001f;
    color: white;
    border: none;
    font-weight: bold;
    padding: 15px;
    cursor: pointer;
    margin-top: 20px;
}

.service-request-form .send-service-request:disabled {
    background-color: #DFDFDF;
    cursor: default;
}

.service-request-form .slot-selector {}
.service-request-form .slot-selector .year-select {
    margin-bottom: 10px;
}
.service-request-form .slot-selector .year-select .year {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;
}
.service-request-form .slot-selector .year-select .year.active {
    font-size: 18px;
    font-weight: bold;
    cursor: default;
}
.service-request-form .slot-selector .year-select .select-date-description {
    display: inline-block;
    margin-right: 15px;
}
.service-request-form .slot-selector .dates {
    margin-left: -10px;
    margin-bottom: 15px;
}
.service-request-form .slot-selector .dates .date {
    display: inline-block;
    margin: 5px 10px;
    cursor: pointer;
}
.service-request-form .slot-selector .dates .date.active {
    color: #c7001f;
    cursor: default;
    font-weight: bold;
}
.service-request-form .slot-selector .select-slot-title {
    margin-bottom: 10px;
}
.service-request-form .slot-selector .slots {
    margin-left: -10px;
    margin-bottom: 25px;
}
.service-request-form .slot-selector .slots .slot {
    display: inline-block;
    margin: 5px 10px;
    cursor: pointer;
}
.service-request-form .slot-selector .slots .slot.active {
    color: #c7001f;
    cursor: default;
    font-weight: bold;
}

.success-create-serviceRequest-modal {
    border-radius: 0px !important;
    width: 360px !important;
}
.success-create-serviceRequest-modal .content{
    text-align: center;
}

.success-create-serviceRequest-modal .title {
    font-weight: bold;
    font-size: 18px;
    text-align: left;
    margin-top: 15px;
    margin-bottom: 15px;
}
.success-create-serviceRequest-modal p {
    text-align: left;
    width: 300px;
}
.success-create-serviceRequest-modal p.important {
    color: #c7001f;
    font-weight: bold;
}

.service-request-page .service-request-item {
    background-color: white;
    width: 607px;
    padding: 20px;
    padding-top: 30px;
    margin-bottom: 20px;
    margin-top: 40px;
}
.service-request-page .service-request-item .item-properties {}
.service-request-page .service-request-item .item-properties .row {
    padding: 0px;
    height: 30px;
}
.service-request-page .service-request-item .item-properties .row .column {}
.service-request-page .service-request-item .item-properties .row .column:first-child {
    color: #B2B2B2;
}
.service-request-page .service-request-item .item-properties .row .column:last-child {}
.service-request-page .service-request-item .item-properties .row .column:last-child .approve-state {
    color: #23B062;
    font-weight: bold;
}
.service-request-page .service-request-item .item-properties .row .column:last-child img {
    vertical-align: sub;
    margin-right: 10px;
}

.service-request-page .service-request-item .cancel-request-btn {
    width: 100%;
    background-color: white;
    border: 1px solid #595959;
    padding: 15px;
    cursor: pointer;
    margin-top: 30px;
}

.service-request-page .description-for-service-request-item {
    color: #c7001f;
    width: 607px;
    padding: 0px 100px;
    text-align: center;
}


.confirm-remove-serviceRequest-modal {
    border-radius: 0px !important;
    width: 500px !important;
    text-align: center;
}

.confirm-remove-serviceRequest-modal {}
.confirm-remove-serviceRequest-modal .title {
    text-align: center;
    font-weight: bold;
    font-size: 18px;
    color: #c7001f;
}
.confirm-remove-serviceRequest-modal p {
    text-align: center;
    padding: 18px 122px;
}
.confirm-remove-serviceRequest-modal .grid {}
.confirm-remove-serviceRequest-modal .grid .row {}
.confirm-remove-serviceRequest-modal .grid .row .column {
    cursor: pointer;
    text-align: center;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .service-request-page {
        padding-left: 12px;
        padding-right: 0px;
    }

    .service-request-page .header h1 {
        font-size: 21px;
    }

    .service-request-page .header {
        margin-left: 45px;
        margin-right: 0px;
    }
    .service-request-page .service-request-form {
        width: auto;
    }

    .service-request-page .service-request-item {
        width: auto;
    }
    .service-request-page .description-for-service-request-item{
        width: auto;
        padding: 0;
    }

    .service-request-page .service-request-item .item-properties .row {
        height: 60px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
    .service-request-page {
        padding-left: 140px;
        padding-right: 20px;
    }
    
    .service-request-form {
        width: auto;
    }

    .service-request-page .service-request-item, .service-request-page .description-for-service-request-item {
        width: auto;
    }
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}