.question-scale-input {}
.question-scale-input .scale-item {
    display: inline-block;
    width: 47px;
    height: 47px;
    font-size: 18px;
    font-weight: bold;
    padding-top: 13px;
    text-align: center;
    border: 1px solid black;
    cursor: pointer;
}

.question-scale-input.is-complete .scale-item {
    cursor: default;
    color: white;
}

.question-scale-input .scale-item.active {
    border: 1px solid transparent;
}
.question-scale-input .scale-item.active.scale-1 {background-color: #8A2B30}
.question-scale-input .scale-item.active.scale-2 {background-color: #993132}
.question-scale-input .scale-item.active.scale-3 {background-color: #AB353A}
.question-scale-input .scale-item.active.scale-4 {background-color: #BA3D40}
.question-scale-input .scale-item.active.scale-5 {background-color: #D14346}
.question-scale-input .scale-item.active.scale-6 {background-color: #E84A4F}
.question-scale-input .scale-item.active.scale-7 {background-color: #FBA82C}
.question-scale-input .scale-item.active.scale-8 {background-color: #E39729}
.question-scale-input .scale-item.active.scale-9 {background-color: #24B062}
.question-scale-input .scale-item.active.scale-10{background-color: #198B4B}