.select-dealer-input {
    border: 1px solid #DFDFDF;
    padding: 12px 20px;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
}
.select-dealer-input.is-empty {
    background-color: #F5F5F5 !important;
}
.select-dealer-input .empty-dealer-message {
    color: #868686 !important;
}
.select-dealer-input i {
    position: absolute;
    top: 11px;
    right: 13px;
}