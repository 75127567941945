.service-calc-page {
    padding-left: 290px;
}

.service-calc-page iframe {
    border: 0;
    width: 100%;
}

/*Phone*/
@media (min-width: 320px) and (max-width: 480px) and (orientation: portrait) {
    .service-calc-page {
        padding-left: 0px;
        padding-right: 0px;
        padding-top: 0px;
    }
}

@media (min-width: 480px) and (max-width: 640px) and (orientation: landscape) {  }

/*Pad*/
@media (min-width: 640px) and (max-width: 960px) and (orientation: portrait) {
}

@media (min-width: 960px) and (max-width: 1200px) and (orientation: landscape) {  }

/*Screen*/
@media (min-width: 1200px) and (orientation: landscape) {}